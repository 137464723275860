import type { Aurelia } from 'aurelia-framework';
import type { I18N } from 'aurelia-i18n';
import { TCustomAttribute } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';
import 'main.scss';
import 'whatwg-fetch';
import { ApplicationEnvironment } from './ApplicationEnvironment';
import { ApplicationState } from './ApplicationState';
import { Data } from './Data/Data';
import { SqClientApi } from './Data/SqClientApi';
import { LoaderEvent } from './Events/LoaderEvent';
import { GlobalFlags } from './GlobalFlags';
import { Initialisation } from './Initialisation';
import { Logger } from './Logger';
import { RemoteSupport } from './RemoteSupport';
import { RethinkDbAuthClient } from './Server/RethinkDbAuthClient';
import { Stopwatch } from './Stopwatch';
import { ThemeService } from './Theme/ThemeService';
import { initialLoader } from './initialLoader';
import './lib/material-design-lite/dist/material';

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const LocaleResourceBundle = require('i18next-resource-store-loader!./locales/index.js');

ThemeService.preInit();

export async function configure(aurelia: Aurelia) {
    try {
        await Initialisation.run();

        window.sq = SqClientApi.getSqApi();

        await RethinkDbAuthClient.clearFreeSession();

        const stopwatch = new Stopwatch('App Init');

        stopwatch.lap('Initialisation run');

        const aureliaUse = aurelia.use.standardConfiguration();

        if (GlobalFlags.isDevServer) aureliaUse.developmentLogging();

        await initialLoader('Loading application modules');

        await aureliaUse
            .plugin(PLATFORM.moduleName('aurelia-mdl-plugin'))

            .plugin(PLATFORM.moduleName('aurelia-i18n'), async (instance: I18N) => {
                const aliases = ['localise'];
                // add aliases for 'localise' attribute
                TCustomAttribute.configureAliases(aliases);

                instance.setup({
                    resources: LocaleResourceBundle,
                    nsSeparator: false,
                    lng: 'en',
                    attributes: aliases,
                    fallbackLng: 'en',
                    debug: false,
                });
                await instance.i18nextReady();
                ApplicationEnvironment.i18nInstance = instance;
                (<any>instance).realTr = instance.tr;
                (<any>instance).tr = (key: any, options: any) => ApplicationState.localise(key, options);
                return instance;
            })
            .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
            .plugin(PLATFORM.moduleName('@aurelia-mdc-web/all'))
            .plugin(PLATFORM.moduleName('@appex/aurelia-dompurify'))
            .globalResources([
                PLATFORM.moduleName('Components/ScrollbarCustomAttribute'),
                PLATFORM.moduleName('Components/TheDestroyerCustomAttribute'),
                PLATFORM.moduleName('Components/Subscription/IfSubscriptionLevelCustomAttribute'),
                PLATFORM.moduleName('Components/Roles/IfIsInAnyRoleCustomAttribute'),
                PLATFORM.moduleName('Attributes/hidden-on'),
                PLATFORM.moduleName('Converters/DateValueConverter'),
                PLATFORM.moduleName('Converters/LocaliseValueConverter'),
                PLATFORM.moduleName('Converters/UsernameValueConverter'),
                PLATFORM.moduleName('Components/Lists/list-item'),
                PLATFORM.moduleName('Components/Lists/list.html'),
                PLATFORM.moduleName('Users/Components/created-by'),
                PLATFORM.moduleName('Users/Components/last-updated-by'),
                PLATFORM.moduleName('Components/Skeleton/skeleton'),
                PLATFORM.moduleName('Components/AvatarCustomElement'),
                PLATFORM.moduleName('Components/Financial'),
                PLATFORM.moduleName('Components/Tags/ui-tag'),
                PLATFORM.moduleName('Components/Typography/headings/section-heading.html'),
                PLATFORM.moduleName('Attachments/Components/attachment-summary-list-item'),
                PLATFORM.moduleName('Components/Badge/badge'),
                PLATFORM.moduleName('Components/paper/paper'),
                PLATFORM.moduleName('Components/Cards/basic-card.html'),
                PLATFORM.moduleName('Components/Layout/container'),
                PLATFORM.moduleName('Components/Toggles/selectable'),
                PLATFORM.moduleName('Converters/NumberToCurrencyValueConverter'),
                PLATFORM.moduleName('Menus/Components/action-slot-more-menu'),
                PLATFORM.moduleName('Daynotes/components/daynote'),
                PLATFORM.moduleName('Settings/Components/toggle-setting'),
                PLATFORM.moduleName('Components/Pagination/pagination'),
            ])

            .apply();

        await initialLoader('Starting the app');

        stopwatch.lap('Aurelia init');

        await aurelia.start();

        ApplicationEnvironment.aurelia = aurelia;

        stopwatch.lap('Aurelia start');

        await RethinkDbAuthClient.checkQuerySession();

        stopwatch.lap('Rethink check query session');

        if (GlobalFlags.isHttp) {
            try {
                const matches = /(https?:\/\/.*?)(?:\/|$)/.exec(document.location.href || '');
                if (matches && matches.length === 2) {
                    const url = matches[1];

                    Logger.info('Registering custom URL handler at ' + url);

                    const nav = <any>navigator;
                    if (nav && nav.registerProtocolHandler) {
                        nav.registerProtocolHandler('web+squeegee', `${url}/?goto=%s`, 'Squeegee');
                    }
                }
            } catch (error) {
                Logger.error('Failed to register the url handler', error);
            }
        }

        await initialLoader('Finishing up...');

        RemoteSupport.initRemoteSupport();

        new LoaderEvent(false);

        if (!RethinkDbAuthClient.session || (document.location && document.location.pathname.startsWith('/signup'))) {
            Logger.info(`User launching squeegee`);
            await ApplicationState.setRootLaunch();
        } else {
            Logger.info(`User ${RethinkDbAuthClient.session.id} signed in, attempting to start.`);
            await ApplicationState.setRootStartup();
        }

        stopwatch.lap('ApplicationState set root');
        stopwatch.stop();
    } catch (error) {
        // Removing the session, just in case it's borked.
        if (Data) await Data.clearLocalStorage();
        Logger && Logger.error('Error in aurelia configure', error);

        await ApplicationState.setRootLaunch();
    }
}
