import { animate } from './Utilities';

export const initialLoader = async (text: string) => {
    const loader = document.getElementById('initial-loader');
    if (!loader) return;

    loader.innerText = text;

    await animate();
};
