type CompactNumberOptions = {
    locale?: string;
    notation?: 'compact';
    compactDisplay?: 'short' | 'long';
};

export const formatCompactNumber = (value: number, options?: CompactNumberOptions): string => {
    const { locale = 'en-GB', notation, compactDisplay = 'short' } = options || {};

    const supportsCompactNotation = (() => {
        try {
            const formatted = new Intl.NumberFormat(locale, { notation: 'compact' }).format(1000);
            return formatted !== '1000'; // If fallbacked silently, result won't be compact
        } catch {
            return false;
        }
    })();

    if (notation === 'compact' && supportsCompactNotation) {
        return new Intl.NumberFormat(locale, {
            notation: 'compact',
            compactDisplay,
        }).format(value);
    }

    // --- Polyfill fallback ---
    const thresholds = [
        { value: 1_000_000_000, short: 'B', long: 'billion' },
        { value: 1_000_000, short: 'M', long: 'million' },
        { value: 1_000, short: 'K', long: 'thousand' },
    ];

    for (const t of thresholds) {
        if (value >= t.value) {
            const number = (value / t.value).toFixed(1).replace(/\.0$/, '');
            const suffix = compactDisplay === 'long' ? ` ${t.long}` : t.short;
            return `${number}${suffix}`;
        }
    }

    return value.toLocaleString(locale);
};
