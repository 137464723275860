import type { Customer, Expense, Transaction } from '@nexdynamic/squeegee-common';
import { TransactionType, customerIsMatch, regexFromString, sortByCreatedDateAsc } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import type { IExpensesFilterItemDictionary, ITransactionsFilterItemDictionary } from '../../Filters/Filters';
import { ITransactionsFilterSortOption } from '../../Filters/Filters';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';
import { Utilities } from '../../Utilities';

/**
 * TODO REFACTOR BUSINESS LOGIC OUT OF HERE
 */

@inject(BindingSignaler)
export abstract class TransactionsBase {
    public invertTotals: boolean;

    public openingBalance?: number;
    public currentDate: moment.Moment = moment();
    public transactionByDayArray: Array<{ text: string; total: number; balance: number; transactions: Array<Transaction> }>;
    protected balances = {} as Record<string, number>;
    private _dataChangedEvent: Subscription;

    constructor(protected signaler: BindingSignaler) {}

    public attached() {
        this._dataChangedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(event => {
            if (event.hasAnyType('transactions', 'joboccurrences', 'notifications')) this.loadData();
        });
        this.loadData();
    }

    public detached() {
        this._dataChangedEvent.dispose();
    }

    protected generateBalances() {
        this.balances = {};
        let balance = 0;
        for (const day of Object.keys(this._transactionsByDay).sort()) {
            for (const t of Object.values(this._transactionsByDay[day]).sort(sortByCreatedDateAsc)) {
                balance = balance + t.amount;
                this.balances[t._id] = balance;
            }
        }
    }

    protected _transactionsByDay: { [text: string]: { [transactionId: string]: Transaction } };
    public updateTransactionByDayArray(
        filterAndSort: ITransactionsFilterItemDictionary | IExpensesFilterItemDictionary,
        searchText: string
    ) {
        if (!this._transactionsByDay) return;
        if (!this.transactionByDayArray) this.transactionByDayArray = [];

        let transactionByDayKeys = Object.keys(this._transactionsByDay);

        const transactionsByDayDictionary: Record<
            string,
            { expanded: boolean; text: string; total: number; realTotal: number; balance: number; transactions: Array<Transaction> }
        > = {};

        for (const key of transactionByDayKeys) {
            for (const transaction of Object.values(this._transactionsByDay[key]).sort(sortByCreatedDateAsc)) {
                if (!transactionsByDayDictionary[key])
                    transactionsByDayDictionary[key] = { expanded: true, realTotal: 0, total: 0, balance: 0, transactions: [], text: key };
                transactionsByDayDictionary[key].realTotal += this.invertTotals ? -transaction.amount : transaction.amount;
            }
        }

        for (let i = 0; i < transactionByDayKeys.sort().length; i++) {
            const key = transactionByDayKeys[i];
            const day = transactionsByDayDictionary[key];

            const keyBefore = transactionByDayKeys[i - 1];
            const dayBefore = transactionsByDayDictionary[keyBefore];

            const transactionIds = Object.keys(this._transactionsByDay[key]);

            for (let i = 0; i < transactionIds.length; i++) {
                const id = transactionIds[i];
                const thisTransaction = this._transactionsByDay[key][id];

                if (this.includeTransaction(thisTransaction, filterAndSort, searchText)) {
                    if (!day.transactions.some(item => item._id === id)) {
                        const clonedTransaction = Utilities.copyObject(thisTransaction);
                        day.transactions.push(clonedTransaction);
                    } else {
                        Object.assign(day.transactions.filter(item => item._id === id)[0], thisTransaction);
                    }
                    day.total = day.total + (this.invertTotals ? -thisTransaction.amount : thisTransaction.amount);
                }
            }

            if (dayBefore) {
                day.balance = dayBefore.balance + day.realTotal;
            } else {
                day.balance = day.realTotal + (this.openingBalance || 0);
            }

            if (!day.transactions.length) delete transactionsByDayDictionary[key];
        }

        if (filterAndSort && filterAndSort.dateRange && filterAndSort.dateRange.selectedOption) {
            const startDate: string = filterAndSort.dateRange.selectedOption[filterAndSort.dateRange.optionValueKey].start;
            const endDate: string = filterAndSort.dateRange.selectedOption[filterAndSort.dateRange.optionValueKey].end;
            // Include the day if it falls within the date range
            transactionByDayKeys = transactionByDayKeys.filter(
                dayKey => (!startDate || dayKey.substring(0, 10) >= startDate) && (!endDate || dayKey.substring(0, 10) <= endDate)
            );
        }

        this.sortDaySummaries(
            filterAndSort,
            transactionByDayKeys.filter(x => transactionsByDayDictionary[x]).map(day => transactionsByDayDictionary[day])
        );
    }

    protected maxRowCount = 5;

    protected loadMore() {
        //TODO potential bug maxRowCount can get deleted seems wrong to me
        this.maxRowCount = (this.maxRowCount || 5) + 5;
        this.transactionByDayArray = this.transactionsByDayArrayFiltered.slice(0, this.maxRowCount);
    }

    private includeTransaction(
        transaction: Transaction,
        filter: ITransactionsFilterItemDictionary | IExpensesFilterItemDictionary,
        searchText: string
    ): boolean {
        if (TransactionUtils.isVoidForAnotherTransaction(transaction)) return false;
        if (transaction.transactionSubType === 'adjustment.tip') return false;
        const customer = transaction.customerId ? (Data.get(transaction.customerId) as Customer) : null;
        let include = true;
        ///Filter based on search text
        if (searchText) {
            include = false;
            const text = searchText.toLowerCase().trim();
            if (transaction.description && transaction.description.toLowerCase().indexOf(text) > -1) include = true;

            const invoiceNumber = transaction.invoice?.invoiceNumber?.toString();
            if (invoiceNumber && invoiceNumber.includes(text)) include = true;

            const prefix = ApplicationState.account.invoiceSettings.invoicePrefix?.toLowerCase();
            const prefixedInvoiceNumber = prefix && invoiceNumber && `${prefix}${invoiceNumber}`;
            if (prefixedInvoiceNumber && prefixedInvoiceNumber.includes(text)) include = true;

            const reference = transaction.invoice?.referenceNumber.toLowerCase();
            if (reference && reference.includes(text)) include = true;

            const transactionTotal = Math.abs(transaction.amount ?? 0).toString(); // handle negative amounts tooß
            const transactionTotalWithCurrency = `${ApplicationState.currencySymbol()}${transactionTotal}`;

            if (transactionTotalWithCurrency === searchText) include = true;

            const searchTermRegex = regexFromString({ original: text.replace(/  +/g, ' '), flags: ['g', 'i'], commaSeparated: true });
            if (customer && customerIsMatch({ customer, searchTerm: text, regex: searchTermRegex })) include = true;

            if ((transaction.updatedByUser || '').toLowerCase().includes(text)) include = true;

            if (transaction.transactionType === TransactionType.Expense) {
                const expense = transaction as unknown as Expense;
                if (expense.reference?.toLowerCase().includes(text)) include = true;
            }
        }
        //Filter based on status
        if (filter && include) {
            if (filter.status && filter.status.selectedOptions && filter.status.selectedOptions.length) {
                if (transaction.invoice) {
                    include = false;
                    if (
                        filter.status.selectedOptions.some(option => option[filter.status.optionValueKey] === 'paid') &&
                        transaction.invoice.paid
                    )
                        include = true;
                    if (
                        filter.status.selectedOptions.some(option => option[filter.status.optionValueKey] === 'pending') &&
                        transaction.invoice.paid === false
                    )
                        include = true;
                } else {
                    include = filter.status.selectedOptions.some(option => option.value === transaction.status);
                }
            }

            if (transaction.transactionType === TransactionType.Expense) {
                // UITransaction needs changing because it should have expense info on it because it does...
                const expense = transaction as unknown as Expense;
                if (filter.category && filter.category.selectedOptions && filter.category.selectedOptions.length) {
                    include = filter.category.selectedOptions.some(c => expense.category && c.value === expense.category._id);
                }
            }
        }
        return include;
    }

    protected transactionsByDayArrayFiltered: Array<{
        expanded: boolean;
        text: string;
        total: number;
        balance: number;
        transactions: Transaction[];
    }>;
    private sortDaySummaries(
        filter: ITransactionsFilterItemDictionary | IExpensesFilterItemDictionary,
        transactions: Array<{ expanded: boolean; text: string; total: number; balance: number; transactions: Array<Transaction> }>
    ) {
        const reverse =
            filter.sortBy.selectedOption && filter.sortBy.selectedOption.value === ITransactionsFilterSortOption.DATE_OLDEST_NEWEST;

        this.transactionsByDayArrayFiltered = transactions
            .filter(x => !!x && !!x.transactions?.length)
            .sort((a, b) => (reverse ? a.text.localeCompare(b.text) : b.text.localeCompare(a.text)));

        const transactionByDayArray = this.transactionsByDayArrayFiltered.slice(0, this.maxRowCount || 5);

        if (transactionByDayArray.length === this.transactionsByDayArrayFiltered.length) this.maxRowCount = 5;

        for (const tranDaySummary of this.transactionByDayArray) {
            if (!tranDaySummary.transactions?.length) continue;
            tranDaySummary.transactions.sort((a, b) => {
                return reverse ? a.createdDate.localeCompare(b.createdDate) : b.createdDate.localeCompare(a.createdDate);
            });
        }

        this.transactionByDayArray = transactionByDayArray;
    }

    abstract loadData(): Promise<void> | void;
}
