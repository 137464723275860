import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { ScheduleActionDialog } from '../../Dialogs/ScheduleActionDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { ScheduleItem } from '../Components/ScheduleItem';
import type { IScheduleItemAction } from './IScheduleItemAction';
export class Replan implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {}
    public actionType = <ItemActionType>'action-replan';
    public async handler() {
        const dialog = new ScheduleActionDialog([this.scheduleItem]);
        await dialog.show();
        new LoaderEvent(false);
        if (dialog.cancelled) {
            return Promise.resolve(this.scheduleItem);
        } else {
            ScheduleItem.refresh(this.scheduleItem);
            return Promise.resolve(this.scheduleItem);
        }
    }
    public tooltip: TranslationKey = 'actions.replan';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Planner'];
}
