import type { Customer, JobGroup, LngLat, Location, Service, Tag, TranslationKey } from '@nexdynamic/squeegee-common';
import { TagType, notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import { RoundService } from '../../Rounds/RoundService';
import { Utilities } from '../../Utilities';
import { TagService } from '../TagService';
import { SelectableTag } from './SelectableTag';

export class SelectTagsDialog<TTagType extends Tag> extends CustomDialog<Array<TTagType>> {
    protected searchTerm = '';
    protected placeholderText: string;
    protected filteredList: Array<SelectableTag<Tag>> = [];
    protected noItemsLocaliseKey: string;
    protected helperName = 'add-tag';
    protected tagText = 'tag';

    protected tags: Array<SelectableTag<Tag>>;

    constructor(
        selectedTags: Array<TTagType>,
        private tagType: TagType,
        protected minTags = 0,
        protected maxTags = 0,
        protected resourceType = 'job',
        protected distanceSortLngLat?: LngLat,
        protected onlySystemTags = false,
        protected useTheseTags?: Array<TTagType>,
        protected headerText?: TranslationKey
    ) {
        super('selectTagsDialog', '../Tags/Components/SelectTagsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'select-list-dialog select-dialog-with-search no-nav-shadow',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });

        switch (tagType) {
            case TagType.ROUND:
                this.noItemsLocaliseKey = 'no-rounds-found';
                this.tagText = 'round';
                this.placeholderText = 'Enter a round';
                break;
            case TagType.SERVICE:
                this.noItemsLocaliseKey = 'no-services-found';
                this.tagText = 'service';
                this.placeholderText = 'Enter a service';
                break;
            case TagType.EXPENSE_CATEGORY:
                this.noItemsLocaliseKey = 'no-expenses-category-found';
                this.tagText = 'category';
                this.placeholderText = 'Enter a category';
                break;
        }

        const tags = useTheseTags || TagService.getTagsByType(this.tagType, this.onlySystemTags);

        const currentSort = this.getCurrentSort;
        this.tags = tags
            .map(tag => {
                const selectableTag = new SelectableTag(tag);
                selectableTag.isSelected = selectedTags.some(st => st._id === tag._id);
                if (this.tagType === TagType.ROUND) {
                    const location: Location | undefined = (selectableTag.tag as JobGroup).location;

                    if (this.distanceSortLngLat && location && location.lngLat)
                        selectableTag.sortValue = LocationUtilities.getDistanceBetweenPoints(
                            location.lngLat,
                            this.distanceSortLngLat,
                            'miles'
                        );
                    const frequencyText = RoundService.getRoundFrequencyText(selectableTag.tag as JobGroup);
                    selectableTag.secondaryDescription = `${frequencyText !== undefined ? frequencyText + ' ' : ''}${
                        selectableTag.sortValue !== undefined ? ' (' + selectableTag.sortValue.toFixed(1) + 'mi)' : ''
                    }`;
                }
                if (
                    !(
                        ApplicationState.getSetting<boolean>('global.show-portal-services-in-tag-list', false) &&
                        this.tagType === TagType.SERVICE
                    )
                ) {
                    if (selectableTag.isSelected) {
                        return selectableTag;
                    } else if ((tag as Service).showOnDirectory === true) {
                        return null;
                    }
                }
                return selectableTag;
            })
            .filter(notNullUndefinedEmptyOrZero)
            .sort(currentSort);
        this.filteredList = this.tags.slice();
    }

    private static serviceUsageMap: Record<string, number> | undefined;
    protected get serviceUsageMap(): Record<string, number> {
        if (!SelectTagsDialog.serviceUsageMap) {
            SelectTagsDialog.serviceUsageMap = {};
            for (const customer of Data.all<Customer>('customers')) {
                if ((customer.state || 'active') !== 'active') continue;
                for (const job of Object.values(customer.jobs || {})) {
                    if ((job.state || 'active') !== 'active') continue;
                    if (!job.services?.length) continue;
                    for (const service of job.services || []) {
                        const usage = SelectTagsDialog.serviceUsageMap[service._id] || 0;
                        SelectTagsDialog.serviceUsageMap[service._id] = usage + 1;
                    }
                }
            }
        }
        return SelectTagsDialog.serviceUsageMap;
    }

    private static roundUsageMap: Record<string, number> | undefined;
    protected get roundUsageMap(): Record<string, number> {
        if (!SelectTagsDialog.roundUsageMap) {
            SelectTagsDialog.roundUsageMap = {};
            for (const customer of Data.all<Customer>('customers')) {
                if ((customer.state || 'active') !== 'active') continue;
                for (const job of Object.values(customer.jobs || {})) {
                    if ((job.state || 'active') !== 'active') continue;
                    if (!job.rounds?.length) continue;
                    for (const round of job.rounds || []) {
                        const usage = SelectTagsDialog.roundUsageMap[round._id] || 0;
                        SelectTagsDialog.roundUsageMap[round._id] = usage + 1;
                    }
                }
            }
        }
        return SelectTagsDialog.roundUsageMap;
    }

    getCurrentSort = (a: SelectableTag<Tag>, b: SelectableTag<Tag>): number => {
        // Selected tags first
        if (!a.isSelected && b.isSelected) return 1;
        if (a.isSelected && !b.isSelected) return -1;

        // Most used next
        if (a.tag.type === TagType.SERVICE && b.tag.type === TagType.SERVICE) {
            const aUsage = this.serviceUsageMap[a.tag._id] || 0;
            const bUsage = this.serviceUsageMap[b.tag._id] || 0;
            const sort = bUsage - aUsage;
            if (sort !== 0) return sort;
        }

        // Alphabetical last
        if (a.tag.description > b.tag.description) return 1;
        if (a.tag.description < b.tag.description) return -1;

        return 0;
    };

    public updateFilteredList() {
        this.filteredList = this.tags.filter(
            selectableTag => selectableTag.tag.description.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        );
    }

    public add = async () => {
        if (this.onlySystemTags) return;
        try {
            const matchingTag = this.filteredList.filter(
                selectableTag => selectableTag.tag.description.toLowerCase() === this.searchTerm.toLowerCase()
            )[0];

            if (matchingTag) {
                this.toggleSelect(matchingTag);
            } else {
                const tag = await TagService.addTag(this.searchTerm, this.tagType);
                if (!tag) return;

                const selectedTag = new SelectableTag(tag);
                selectedTag.tag._id = tag._id;
                this.tags.unshift(selectedTag);
                this.toggleSelect(selectedTag);
                this.updateFilteredList();
            }
        } catch (error) {
            Logger.error(`Error during add in select tags dialog`, { tagType: this.tagType, tagText: this.tagText, error });
        }
    };

    public toggleSelect(tag: SelectableTag<Tag>) {
        const selected = this.tags.filter(t => t.isSelected);

        if (tag.isSelected) {
            tag.isSelected = false;
        } else {
            if (this.maxTags > 1 && this.maxTags <= selected.length) {
                return ApplicationState.localise('validation.select-x-items', { count: this.maxTags.toString() });
            }

            if (this.maxTags === 1) {
                selected.forEach(t => (t.isSelected = false));
            }
            tag.isSelected = true;
        }

        if (this.searchTerm) {
            this.searchTerm = '';
            this.updateFilteredList();
        }
    }

    public async onValidate(): Promise<true | TranslationKey> {
        return this.minTags === 0 || this.tags.filter(tag => tag.isSelected).length > 0
            ? true
            : ApplicationState.localise('validation.select-x-items', { count: this.minTags.toString() });
    }

    public async getResult() {
        return this.getSelectedClean();
    }

    private getSelectedClean() {
        if (this.onlySystemTags) {
            return Utilities.copyObject(this.tags.filter(tag => tag.isSelected)).map(tag => tag.tag as TTagType);
        } else {
            return Utilities.copyObject(
                this.tags
                    .filter(t => t.isSelected)
                    .map(t => Data.get<TTagType>(t.tag._id) as TTagType)
                    .filter(t => !!t)
            );
        }
    }

    protected selectOk = async () => {
        if (this.searchTerm.length) {
            await this.add();
        } else {
            const selectedTags = this.getSelectedClean();
            this.ok(selectedTags);
            return;
        }
    };
}
